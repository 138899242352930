import {CollectionEntry} from '~/graphql/graphql';

export function gtmSerializeCollectionEntries(entries: Array<Pick<CollectionEntry, 'flower' | 'flower_cultivar'>>) {
  return entries.map(entry => ({
    item_list_id: entry.flower.uuid,
    item_list_name: entry.flower.title_latin,
    item_id: entry.flower_cultivar?.uuid ?? entry.flower.uuid,
    item_name: entry.flower_cultivar?.title ?? entry.flower.title_latin,
  }));
}
