<template>
  <Transition
    enter-active-class="transition"
    leave-active-class="transition"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <slot />
  </Transition>
</template>
