<template>
  <teleport to="#teleports">
    <TransitionsFade>
      <div
        v-if="open"
        class="fixed inset-0 z-[600] grid h-full w-full place-items-center overflow-y-auto bg-black/20 px-4"
        @click="close"
      >
        <FormKit
          :actions="false"
          :classes="{
            'form': 'contents',
          }"
          :preserve="true"
          type="form"
          @click.stop
          @submit="(data, node) => emit('submit', data, node)"
        >
          <div
            class="relative mx-5 w-full rounded-lg bg-white shadow-lg "
            :class="{
              'sm:max-w-[720px]' : !size,
              'sm:max-w-[360px]' : size === 'small',
            }"
          >
            <button
              class="absolute right-6 top-6 z-[900]"
              type="button"
              @click="close"
            >
              <UiIcon name="fa6-solid:xmark" />
            </button>
            <div class="grid max-h-[80vh] overflow-auto md:max-h-[90vh]">
              <div
                v-if="title"
                class="p-6 pb-0"
              >
                <h2 class="text-2xl sm:text-3xl">
                  {{ title }}
                </h2>
              </div>

              <UiLoader
                class="p-6"
                :loading="loading"
              >
                <slot>
                  <p>{{ text }}</p>
                </slot>

                <FormKitMessages />
              </UiLoader>

              <div
                v-if="$slots.controls || cancelLabel || submitLabel"
                class="flex justify-start gap-4 p-6 pt-0"
              >
                <UiButton
                  v-if="cancelLabel !== false"
                  class="w-full"
                  :label="$t('modal.cancel')"
                  type="secondaryOutline"
                  @click="close"
                />

                <UiButton
                  v-if="submitLabel"
                  class="w-full"
                  :label="submitLabel"
                  type="submit"
                />

                <slot
                  name="controls"
                  v-bind="{close}"
                />
              </div>
            </div>
          </div>
        </FormKit>
      </div>
    </TransitionsFade>
  </teleport>
</template>

<script setup lang="ts">
import type {FormKitNode} from '@formkit/core';
import {FormKitMessages} from '@formkit/vue';
import {useScrollLock} from '@vueuse/core';

withDefaults(defineProps<{
  cancelLabel?: string | false
  loading?: boolean
  submitLabel?: string
  text?: string
  title?: string
  size?: 'small'
}>(), {
  cancelLabel: undefined,
  submitLabel: undefined,
  loading: false,
  text: undefined,
  title: undefined,
});

const isLocked = useScrollLock(process.client ? document?.body : null, false);

const open = defineModel<boolean>('open', {
  required: true,
  local: true,
});

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'submit', data: object, form: FormKitNode): void
}>();

// Window stack management
const stackIndex = useState<number>('modal.index', () => 0);
const stack = useState<number[]>('modal.stack', () => []);
const id = ref<number>(-1);

onMounted(() => {
  id.value = stackIndex.value++;
});

watch(open, (open) => {
  isLocked.value = open;

  if (open) {
    stack.value.push(id.value);
  }
}, {immediate: true});

// Close controls
onKeyStroke('Escape', () => {
  if (stack.value.at(-1) === id.value) {
    close();
  }
}, {dedupe: true});

function close() {
  open.value = false;

  stack.value.pop();
}

watch(open, (open) => {
  if (!open) {
    emit('close');
  }
});

defineExpose({
  close,
});
</script>
